<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">No. Payment Request</label>
        <vs-input class="w-full" :value="initData.header.no_payment_request" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">PR Date</label>
        <vs-input class="w-full" :value="initData.header.tgl_payment_request" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">PR Due</label>
        <vs-input class="w-full" :value="initData.header.tgl_due" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Created By</label>
        <vs-input class="w-full" :value="initData.header.created_by" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs text-success">Nilai PR</label>
        <v-money class="w-full" :value="initData.header.total_nilai" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/1 w-full">
        <label class="ml-1 text-xs">Keterangan</label>
        <vs-textarea class="w-full" :value="initData.header.keterangan" readonly/>
      </div>
    </div>
  </div>
</template>

<script>
import VMoney from '@/views/components/v-money/VMoney'

export default {
  name: 'TabHeader',
  components: { VMoney },
  props: {
    isActive: { required: true, type: Boolean },
    initData: { default: null, type: Object }
  },
  methods: {}
}
</script>
